import { useEffect, useState } from "react"

export function useForceRerenderKey(hook){
    const [count, setCount] = useState(0);
    useEffect(() => {
        setCount(cnt => cnt + 1);
    },[hook]);
    return count;
}


