import { useCookies } from 'react-cookie';
import { useEffect } from 'react';

let userDependency;
let setCookie;
let cookies;


function useSetUpUserCookie(){
    const [c_cookies, c_setCookie] = useCookies(['user', 'language']);
    userDependency = c_cookies;
    cookies = c_cookies;
    setCookie = c_setCookie;
    
    // Set the language cookie to 'en' as Default
        // Check if the language cookie is not already set
    if (c_cookies.language === undefined) {
        setCookie('language', 'en', { path: '/' });
    }
    

    return c_cookies;
}

function isUserDefined(){
    return cookies?.user != null;
}

function getUser(){
    const user = cookies?.user;
    const parsedUser = user;
    return parsedUser;
}

function setUser(user){
    const stringifiedUser = JSON.stringify(user);
    setCookie('user', stringifiedUser, {path: '/'});
}


function setLanguage(language){
    setCookie('language', language , {path: '/'});
}
function getLanguage(){
    return cookies.language;
}
function getDirection(){
    if (getLanguage() === 'en'){
        return 'ltr';
    }else{
        return 'rtl';
    }
}

export {useSetUpUserCookie};
export {isUserDefined};
export {getUser};
export {setUser};
export {userDependency};
export {setLanguage};
export {getLanguage};
export {getDirection};
