import React, { useState, useEffect, useRef } from "react";
import { Button } from "@mui/material";
import { Form, Nav } from "react-bootstrap";
import { RiAddCircleFill, RiSearchFill } from "react-icons/ri";
import { Link } from "react-router-dom";
import { Container } from 'react-bootstrap'
import { getUser } from '../utils/userCookie.js'
import { useDirection } from "./DirectionContext.js";
import { t } from "i18next";
function TableControls({ setRequests, onAddClick }) {
  const [searchVal, setSearchVal] = useState({val: "", changed: false});
  const intermediateSearchVal = useRef("");
  
  useEffect(() => {
    if (searchVal.changed){
      setRequests(searchVal.val);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchVal]);

  const direction = useDirection()?.direction;
  return (

    <Container>
      <div className="table-controls" data-testid="tableControls" dir={direction}>
        <div className="table-control-search" style={direction==='ltr'?{left:'5rem'}:{right:'5rem'}}>
          <Form className="d-flex" 
            onSubmit={(event)=> {
              event.preventDefault();
              setSearchVal({val: intermediateSearchVal.current, changed: true});
            }}
            dir={direction}
            //style={{direction: direction}}
          >
            <Form.Control
              type="search"
              placeholder={t("CustomTable.Search")}
              className=""
              aria-label="Search"
              style={{ border: "2px solid var(--lightblue)" }}
              onChange={(event)=> {intermediateSearchVal.current = (event.target.value);}}
            />
            <Button
              variant=""
              style={{
                backgroundColor: "var(--lightblue)",
                color: "var(--white)",
                fontSize: "23px",
                position: "relative",
                right: direction ==='ltr'?"6px":"-6px"
              }}
              type="submit"
            >
              <RiSearchFill />
            </Button>
          </Form>
        </div>
        {getUser()?.privilage !== "ADMIN" && <div style={direction ==='ltr'?{ marginLeft: "auto" }:{ marginRight: "auto" }}>
          <Button
            variant=""
            onClick={onAddClick}
            style={{
              border: "1px solid var(--lightblue)",
              backgroundColor: "var(--lightblue)",
              color: "var(--white)",
              fontSize: "17px",
            }}
          >
            <Nav.Link as={Link} to="/new-request">
              {" "}
              {" "}
              <RiAddCircleFill style={{ fontSize: "23px", marginLeft: "2px", position: "relative", bottom: "2.7px" }} />
            </Nav.Link>{" "}
            {/* Update Link to use Link */}
          </Button>
        </div>}
      </div>
    </Container>
  );
}

export default TableControls;
