import React, { useState, useEffect } from 'react';
import Navbar from "../../Components/Navbar.js";
import { createMessage, getRequestMessages, formatTimestamp, getUserAbbrevName } from '../../utils/util.js';
import { getUser } from "../../utils/userCookie.js";
import { useLocation } from "react-router-dom";
import './ReplyPage.css'; 
import '../../App.css';
import RichTextForm from '../../Components/RichTextForm.js';
import { useTranslation } from 'react-i18next';
import { Avatar, Card, CardContent, Typography, Box, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess'; // Import the "Show Less" icon

function ReplyPage({ usr = null }) {
  const [messages, setMessages] = useState([]);
  const [expandedMessages, setExpandedMessages] = useState({});
  const location = useLocation();
  const request = location.state;
  const user = getUser() ? getUser() : usr;

  const getMessagesFromDB = async () => {
    setMessages(await getRequestMessages(request?._id));
  };

  useEffect(() => {
    getMessagesFromDB();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const requestId = request?._id;

  const handleSubmit = async (reply) => {
    await createMessage(requestId, getUser()?._id, reply);
    getMessagesFromDB();
  };

  const { t } = useTranslation();

  // Toggle full message view
  const toggleMessageExpansion = (id) => {
    setExpandedMessages((prev) => ({
      ...prev,
      [id]: !prev[id], // Toggle the expanded state
    }));
  };

  return (
    <div data-testid="ReplyPage">
        <div className="content-container">
          {/* Request Header (Centered) */}
          <div className="title-name-container">
            <div className="title_container" >
              <h3 style={{ textAlign: "center" , paddingTop: '40px' }}><strong>{request?.subject}</strong></h3>
              <br />
              <div style={{ textAlign: "center" }} dangerouslySetInnerHTML={{ __html: request?.info }} />
            </div>
            <p className="name-time" style={{ textAlign: "center" , paddingBottom: '15px' }}>
              {getUserAbbrevName(request?.user_id.first_name, request?.user_id.last_name)} 
              &nbsp;&nbsp;&nbsp; {formatTimestamp(request?.createdAt)}
            </p>
          </div>

          {/* Message List (Not Centered) */}
          <div className="messages-container">
            {messages && messages.map((message, index) => (
              <Box 
                className={`message-box ${message.sender_id === request?.user_id._id ? "right-align" : "left-align"}`} 
                key={message._id}
                display="flex"
                alignItems="center"
                mb={2}
                sx={{
                  justifyContent: message.sender_id === request?.user_id._id ? 'flex-end' : 'flex-start',
                }}
              >
                {message.sender_id !== request?.user_id._id && (
                  <Avatar 
                    alt={message.name} 
                    src={message.avatarUrl} 
                    sx={{ marginRight: '16px' }}
                  />
                )}
                <Card
                  sx={{
                    maxWidth: '70%',
                    backgroundColor: message.sender_id === request?.user_id._id ? '#f1f1f1' : '#A8DDFD',
                    borderRadius: 3,
                    boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.2)',
                    wordWrap: 'break-word'
                  }}
                >
                  <CardContent>
                    {/* User's name as title */}
                    <Typography variant="h6" component="div" fontWeight="bold" gutterBottom>
                      {message.name}
                    </Typography>
                    {/* Message content with max length 4 lines */}
                    <Typography 
                      variant="body1" 
                      sx={{
                        overflow: expandedMessages[message._id] ? 'visible' : 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitLineClamp: expandedMessages[message._id] ? 'none' : 4,
                        WebkitBoxOrient: 'vertical',
                      }}
                      dangerouslySetInnerHTML={{ __html: message.info }}
                    />
                    {/* Expand button to show more or show less */}
                    {message.info.split(' ').length > 20 && (
                      <IconButton size="small" onClick={() => toggleMessageExpansion(message._id)}>
                        {expandedMessages[message._id] ? (
                          <ExpandLessIcon fontSize="small" /> // Show Less
                        ) : (
                          <ExpandMoreIcon fontSize="small" /> // Show More
                        )}
                      </IconButton>
                    )}
                    {/* Timestamp */}
                    <Typography variant="caption" color="textSecondary">
                      {formatTimestamp(message.createdAt)}
                    </Typography>
                  </CardContent>
                </Card>
                {message.sender_id === request?.user_id._id && (
                  <Avatar 
                    alt={message.name} 
                    src={message.avatarUrl} 
                    sx={{ marginLeft: '16px' }}
                  />
                )}
              </Box>
            ))}
          </div>

          {/* Reply Form */}
          {user?.privilage === "USER" && (request?.user_id._id === getUser()._id || request?.handler_id?._id === getUser()._id) && (
            <div className="message-form-container">
              <RichTextForm onSubmit={handleSubmit} />
            </div>
          )}
        </div>
    </div>
  );
}

export default ReplyPage;