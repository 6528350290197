import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  InputGroup,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Navbar from "../Components/Navbar.js";
import {
  FaUser,
  FaBuilding,
  FaUserTie,
  FaSuitcase,
} from "react-icons/fa"; // Import necessary icons
import { Link } from "react-router-dom";
import "./login.css";
import { addUser, getUserEmailAddressByToken } from "../utils/util.js";


const SignUp = () => {
  const navigate = useNavigate();


  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    organization: '',
    position: '',
    about: ''
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };



  const firstNameKey = "first_name"
  const lastNameKey = "last_name"
  const organizationKey = "orginization"
  const positionKey = "position"
  const emailKey = "email_address"
  const aboutKey = "about"
  const submitList = [
    {
      key: firstNameKey,
      ref: formData.firstName
    },
    {
      key: lastNameKey,
      ref: formData.lastName
    },
    {
      key: positionKey,
      ref: formData.position
    },
    {
      key: organizationKey,
      ref: formData.organization
    },
    {
      key: emailKey,
      ref: null
    },

    {
      key: aboutKey,
      ref: formData.about
    },
  ]

  const handleSubmit = async (e) => {
    e.preventDefault();
    const handleResponseNotOk = (response) => {
      console.log(response);
      console.error("Network response was not ok", response);
    }
    const handleResponseOk = async (response) => {
      const data = await response.json();
      console.log("Server response:", data);
      navigate("/");
    }
    const handleConnectionError = (error) => {
      console.error("Error:", error.message);
    }
    var inputs = {};
    submitList.forEach((item) => {
      inputs[item.key] = item.ref;
    })
    inputs[emailKey] = await getUserEmailAddressByToken();
    console.log("inputs:", inputs);
    try {
      const response = await addUser(inputs);
      if (!response.ok) {
        handleResponseNotOk(response);
        return;
      }
      // Handle the response here
      await handleResponseOk(response);
      return;
    } catch (error) {
      await handleConnectionError(error);
      return;
    }
  };

  const iconstyle = {
    position: "relative",
    bottom: "2.9px",
  };
  const [menuActive, setMenuActive] = useState(false);

  const handleMenuToggle = () => {
    setMenuActive(!menuActive);
  };
  return (
    <div data-testid="signup">
      <Navbar />
      <main></main>
      <Container>
        <Row className="justify-content-center mt-5">
          <Col lg={8} md={8} sm={12}>
            <Card
              style={{
                border: "none",
                margin: "2rem 0",
                boxShadow:
                  "rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset",
              }}
            >
              <Card.Body>
                <Card.Title className="text-center border-bottom p-3">
                  <h2
                    style={{
                      fontFamily: "Chasm",
                      fontWeight: "600",
                      color: "#333",
                      textAlign: "center",
                      marginTop: "2px",
                    }}
                  >
                    SIGNUP
                  </h2>
                  <p
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "grey",
                    }}
                  >
                    Hey and welcome to our community, Your request will be
                    examined and the feedback will be send to the entered email
                    at the earliest possible opportunity
                  </p>
                </Card.Title>
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col lg={6} md={6} sm={12}>
                      <Form.Group className="mb-3">
                        <Form.Label
                          htmlFor="firstName"
                          style={{
                            fontSize: "15px",
                            fontWeight: "600",
                            color: "grey",
                          }}
                        >
                          <FaUser style={iconstyle} /> First Name
                        </Form.Label>
                        <InputGroup
                          style={{
                            border: "0.5px solid #D3D3D3",
                            borderRadius: "5px",
                          }}
                        >
                          <Form.Control type="text" id="firstName" value={formData.firstName} onChange={handleChange} />
                          <Button
                            variant="none"
                            style={{ border: "2px solid white" }}
                          ></Button>
                        </InputGroup>

                      </Form.Group>
                    </Col>
                    <Col lg={6} md={6} sm={12}>
                      <Form.Group className="mb-3">
                        <Form.Label
                          htmlFor="lastName"
                          style={{
                            fontSize: "15px",
                            fontWeight: "600",
                            color: "grey",
                          }}
                        >
                          <FaUser style={iconstyle} /> Last Name
                        </Form.Label>
                        <InputGroup
                          style={{
                            border: "0.5px solid #D3D3D3",
                            borderRadius: "5px",
                          }}
                        >
                          <Form.Control type="text" id="lastName" value={formData.lastName} onChange={handleChange} />
                          <Button
                            variant="none"
                            style={{ border: "2px solid white" }}
                          ></Button>
                        </InputGroup>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={6} md={6} sm={12}>
                      <Form.Group className="mb-3">
                        <Form.Label
                          htmlFor="organization"
                          style={{
                            fontSize: "15px",
                            fontWeight: "600",
                            color: "grey",
                          }}
                        >
                          <FaBuilding style={iconstyle} /> Organization
                        </Form.Label>
                        <InputGroup
                          style={{
                            border: "0.5px solid #D3D3D3",
                            borderRadius: "5px",
                          }}
                        >
                          <Form.Control type="text" id="organization" value={formData.organization} onChange={handleChange} />
                          <Button
                            variant="none"
                            style={{ border: "2px solid white" }}
                          ></Button>
                        </InputGroup>
                      </Form.Group>
                    </Col>
                    <Col lg={6} md={6} sm={12}>
                      <Form.Group className="mb-3">
                        <Form.Label
                          htmlFor="position"
                          style={{
                            fontSize: "15px",
                            fontWeight: "600",
                            color: "grey",
                          }}
                        >
                          <FaUserTie style={iconstyle} /> Position
                        </Form.Label>
                        <InputGroup
                          style={{
                            border: "0.5px solid #D3D3D3",
                            borderRadius: "5px",
                          }}
                        >
                          <Form.Control type="text" id="position" value={formData.position} onChange={handleChange} />
                          <Button
                            variant="none"
                            style={{ border: "2px solid white" }}
                          ></Button>
                        </InputGroup>
                      </Form.Group>
                    </Col>
                  </Row>


                  <Form.Group className="mb-3">
                    <Form.Label
                      htmlFor="about"
                      style={{
                        fontSize: "15px",
                        fontWeight: "600",
                        color: "grey",
                      }}
                    >
                      <FaSuitcase style={iconstyle} /> About
                    </Form.Label>
                    <InputGroup
                      style={{
                        border: "0.5px solid #D3D3D3",
                        borderRadius: "5px",
                      }}
                    >
                      <Form.Control
                        as="textarea"
                        id="about"
                        rows="4"
                        cols="50"
                        value={formData.about}
                        onChange={handleChange}
                      />
                    </InputGroup>
                  </Form.Group>
                  <div className="d-grid  mb-3">
                    <Button
                      type="submit"
                      className="btn"
                      style={{
                        backgroundColor: "var(--bluegrey)",
                        color: "var(--grey)",
                        fontWeight: "600",
                        border: "2px solid var(--bluegrey)",
                      }}
                    >
                      SIGNUP
                    </Button>
                  </div>

                  <p
                    style={{
                      fontSize: "16px",
                      textAlign: "center",
                      fontWeight: "500",
                      color: "grey",
                    }}
                  >
                    Already have an account?
                    <Link to="/log-out" onClick={handleMenuToggle}>
                      Login
                    </Link>
                  </p>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      <div className="intro-svg">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path
            fill="#C6D5E9"
            fillOpacity="1"
            d="M0,32L48,58.7C96,85,192,139,288,181.3C384,224,480,256,576,240C672,224,768,160,864,122.7C960,85,1056,75,1152,96C1248,117,1344,171,1392,197.3L1440,224L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
          ></path>
        </svg>
      </div>
    </div>
  );
};

export default SignUp;
