import React, { useEffect, useRef, useState } from 'react';
import { Container, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import { FaBan } from 'react-icons/fa';
import { microServices, getMicroserviceUri } from '../../utils/uri.js';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import { useDirection } from '../../Components/DirectionContext.js';
import { useTranslation } from 'react-i18next';
import BlockIcon from '@mui/icons-material/Block';
import { fetchWrapper } from '../../utils/util.js';
import { Form } from 'react-bootstrap';
import { RiSearchFill } from 'react-icons/ri';






async function fetchAllUsers() {
    var response;
    try {
        response = await fetchWrapper(getMicroserviceUri(microServices.USERS) + '/getAllUsers',
            'GET',
            {
                'Content-Type': 'application/json',
            },
        );
    } catch (e) {
        console.log("fetch error", e);
    }
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    const data = await response.json();
    console.log(data);
    return data;
}

async function updateStatus(stat, email_address) {
    const inputs = {
        email_address: email_address,
        approved: stat
    };
    const uri_ = getMicroserviceUri(microServices.USERS) + '/updateUserStatus';
    await sendRequest(inputs, uri_);
}

async function sendRequest(inputs, uri) {
    var response;
    try {
        response = await fetchWrapper(uri,
            'POST',
            {
                'Content-Type': 'application/json',
            },
            JSON.stringify(inputs),
        );
    } catch (e) {
        console.log("fetch error", e);
    }
    if (!response.ok) {
        console.log("response:");
        console.log(response);
        console.log('Network response was not ok', response);
    }
    const data = await response.json();
    console.log('Server response:', data);
}

async function blockUser(email_address) {
    const inputs = {
        email_address: email_address,
    };
    const uri = getMicroserviceUri(microServices.USERS) + '/blockUser';
    await sendRequest(inputs, uri);
}


const Users = () => {

    const {t} = useTranslation();

    const [users, setUsers] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedUser, setSelectedUser] = useState(null);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        fetchUsers();
    }, []);

    const fetchUsers = async () => {
        try {
            const data = await fetchAllUsers();
            setUsers(data.filter(user => user.approved)); // Filter users to include only approved ones
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleDeleteClick = (user) => {
        setSelectedUser(user);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedUser(null);
    };

    const handleDelete = async () => {
        if (!selectedUser) return;

        try {

                    // Block the user
             await blockUser(selectedUser.email_address);


            // Update user status to approved = false
            await updateStatus(false, selectedUser.email_address);

            // Remove the user from the list
            setUsers(users.filter((user) => user._id !== selectedUser._id));

            handleClose();
        } catch (error) {
            console.error('Error updating user status:', error);
        }
    };

    // Filter users based on the search term
    const filteredUsers = users.filter((user) => {
        const fullName = `${user.first_name} ${user.last_name}`.toLowerCase();
        return fullName.includes(searchTerm.toLowerCase()) || 
               user.email_address.toLowerCase().includes(searchTerm.toLowerCase()) ||
               user.orginization.toLowerCase().includes(searchTerm.toLowerCase());
    });

    const direction = useDirection()?.direction;

    return (
        <div data-testid="users">
        <Container style={{ direction: direction, padding: 0, margin: 0 }}>
            {/* <TextField
                label={t("CustomTable.Search")}
                variant="outlined"
                value={searchTerm}
                onChange={handleSearchChange}
                fullWidth
                dir={useDirection()?.direction}
                style={{ marginBottom: '1rem' }}
                
            /> */}
            <Form className="d-flex" 
            onSubmit={(event)=> {
              event.preventDefault();
              handleSearchChange(event);
            }}
            dir={direction}
            style={{margin: 'auto 5px 15px auto'}}
          >
            <Form.Control
              type="search"
              placeholder={t("CustomTable.Search")}
              className=""
              aria-label="Search"
              style={{ border: "2px solid var(--lightblue)" }}
              onChange={handleSearchChange}
            />
            <Button
              variant=""
              style={{
                backgroundColor: "var(--lightblue)",
                color: "var(--white)",
                fontSize: "23px",
                position: "relative",
                right: direction ==='ltr'?"6px":"-6px"
              }}
              type="submit"
            >
              <RiSearchFill />
            </Button>
          </Form>
            <div style={{ overflowX: "auto", width: "100%", padding: 0, margin: 0 }}>
                <table style={{ width: "100%", minWidth: "600px", padding: 0, margin: 0 }}>
                    <thead>
                        <tr>
                        <th >{t("UserPending.name")}</th>
                        <th>{t("UserPending.email")}</th>
                        <th >{t("UserPending.organization")}</th>
                        <th>{t("Other.Actions")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredUsers.map((user) => (
                            <tr key={user._id}>
                                <td>{user.first_name} {user.last_name}</td>
                                <td>{user.email_address}</td>
                                <td>{user.orginization}</td>
                                <td>
                                    <Button
                                        onClick={() => handleDeleteClick(user)}
                                        style={{
                                            color: "red",
                                        }}
                                    >
                                        <BlockIcon />
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <Dialog
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>{"Confirm Blocking"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to Block {selectedUser?.first_name} {selectedUser?.last_name}?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDelete} color="secondary">
                        Block
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
        </div>
    );
};

export default Users;
