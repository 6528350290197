export const customTableTabs = {
    incoming: 0,
    sent: 1,
    public: 2
}

function navigateToTab(nav, tab){
    nav('/', { state: {customTableTabs: tab}})
}

export function navigateToIncoming(nav){
    navigateToTab(nav, customTableTabs.incoming)
}

export function navigateToSent(nav){
    navigateToTab(nav, customTableTabs.sent)
}

export function navigateToPublic(nav){
    navigateToTab(nav, customTableTabs.public)
}

function isTab(location, tab, defaultOption){
    if (location.state == null || location.state.customTableTabs == null){
        return defaultOption;
    }
    return location.state.customTableTabs === tab;

}

export function isIncomingTab(location){
    return isTab(location, customTableTabs.incoming, false);
}

export function isSentTab(location){
    return isTab(location, customTableTabs.sent, true);
}

export function isPublicTab(location){
    return isTab(location, customTableTabs.public, false);
}

export function getTab(location){
    if (isSentTab(location)){
        return customTableTabs.sent;
    }else if (isIncomingTab(location)){
        return customTableTabs.incoming;
    }else{
        return customTableTabs.public;
    }
}