import React, { useState, useRef, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import ReactNavbar from "react-bootstrap/Navbar";
import logo from "../assets/logo.png";
import { getLanguage, getUser, isUserDefined, setLanguage } from '../utils/userCookie.js';
import { downloadUsersDataAsExcel, downloadRequestsDataAsExcel, fetchWrapper } from '../utils/util.js';
import { microServices, getMicroserviceUri } from '../utils/uri.js'; // Import microservice utilities
import { useTranslation } from 'react-i18next';
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Logout from "@mui/icons-material/Logout";
import NestedMenuItem from "./NestedMenuItem";
import { useDirection } from './DirectionContext';



// Import avatar images
import avatar1 from '../assets/Avatar/1.png';
import avatar2 from '../assets/Avatar/2.png';
import avatar3 from '../assets/Avatar/3.png';
import avatar4 from '../assets/Avatar/4.png';
import avatar5 from '../assets/Avatar/5.png';
import { navigateToSent } from "../utils/customTableTabsUtils.js";
import { navigateToIncoming } from "../utils/customTableTabsUtils.js";
import { navigateToPublic } from "../utils/customTableTabsUtils.js";
import { isSentTab } from "../utils/customTableTabsUtils.js";
import { isIncomingTab } from "../utils/customTableTabsUtils.js";
import { isPublicTab } from "../utils/customTableTabsUtils.js";

const avatarMap = {
  1: avatar1,
  2: avatar2,
  3: avatar3,
  4: avatar4,
  5: avatar5,
};

function Navbar() {
  const [expanded, setExpanded] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);  // State for dialog open
  const [selectedAvatar, setSelectedAvatar] = useState(avatar1);  // State for selected avatar
  const [tempAvatar, setTempAvatar] = useState(avatar1);  // Temporary avatar selection

  const user = getUser();
  const { setDirection } = useDirection();

  function updateLanguage(language){
    i18n.changeLanguage(language);
    setLanguage(language);
    if (language == 'en'){
      setDirection('ltr');
    }else{
      setDirection('rtl');
    }
  }

  useEffect(() => {
    updateLanguage(getLanguage());
  }, [])

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  const handleMenuClick = (event) => {
    setMenuAnchorEl(event.currentTarget);
    document.body.style.overflow = 'hidden'; // Disable body scroll
  };

  const handleClose = () => {
    setMenuAnchorEl(null);
    document.body.style.overflow = ''; // Re-enable body scroll
  };

  const handleAvatarClick = () => {
    setTempAvatar(selectedAvatar);  // Store the current avatar in case the user cancels
    setDialogOpen(true);
    handleClose(); // Close the menu when opening the dialog
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleAvatarSelect = (avatar) => {
    setTempAvatar(avatar);  // Update the temporary avatar selection
  };

  const handleApplyAvatar = async () => {
    const avatarNumber = Object.keys(avatarMap).find(key => avatarMap[key] === tempAvatar);
    const inputs = { email_address: user?.email_address, avatar: avatarNumber };

    const uri = getMicroserviceUri(microServices.USERS) + '/setAvatar';

    try {
      const response = await fetchWrapper(uri, 'POST', {
        'Content-Type': 'application/json',
      }, JSON.stringify(inputs));

      if (response) {
        setSelectedAvatar(tempAvatar);  // Apply the temporary avatar selection
        setDialogOpen(false);
      } else {
        console.error('Error updating avatar: No response from server');
      }
    } catch (error) {
      console.error('Error updating avatar:', error);
    }
  };

  const fetchAvatar = async () => {
    const inputs = { email_address: user?.email_address };
    const uri = getMicroserviceUri(microServices.USERS) + '/getAvatar';
  
    try {
      let response = await fetchWrapper(uri, 'POST', {
        'Content-Type': 'application/json',
      }, JSON.stringify(inputs));
      if(!response.ok)
      {
        console.error("Bad Response from server")
        return
      }
      response = await response.json()

      if (response && response.avatar) {
        console.log("Fetched avatar:", response.avatar); // Debugging line
        setSelectedAvatar(avatarMap[response.avatar]);
      } else {
        console.error("Fetched avatar:", response.avatar); // Debugging line

        console.error('Error fetching avatar: No response from server or avatar not set');
      }
    } catch (error) {
      console.error('Error fetching avatar:', error);
    }
  };
  
  useEffect(() => {
    fetchAvatar();
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toPendingPage = () => navigate('/user-pending');
  const toPublicRequestsPage = () => navigateToPublic(navigate);
  const toMyRequestsPage = () => navigateToSent(navigate);
  const toIncomingRequestsPage = () => navigateToIncoming(navigate);

  const [height, setHeight] = useState(0);
  const navRef = useRef(null);

  useEffect(() => {
    if (!navRef.current) return;
    const resizeObserver = new ResizeObserver((entries) => {
      const [nav] =  entries;
      setHeight(nav.contentRect.height);
    });
    resizeObserver.observe(navRef.current);
    return () => resizeObserver.disconnect(); // clean up 
  }, [navRef]);

  const isActive = (path) => location.pathname === path;

  

  return (
    <div data-testid="navBar">
      <div ref={navRef}  style={{
          backgroundColor: "var(--seablue)",
          width: "100%",
          position: "fixed",
          top: 0,
          zIndex: 1000,
          border: "4px solid var(--seablue)",
        }}>
        <ReactNavbar expand="lg" className="" expanded={expanded} style={{ width: "100%", top: 0}}>
          <Container>
            <ReactNavbar.Brand as={Link} to="/" onClick={() => setExpanded(false)}>
              <img
                src={logo}
                alt=""
                style={{
                  margin: "0 2rem",
                  position: "relative",
                  bottom: "6px"
                }}
              />
            </ReactNavbar.Brand>
            {isUserDefined() && <ReactNavbar.Toggle aria-controls="navbarScroll" onClick={handleToggle} />}
            {isUserDefined() && <ReactNavbar.Collapse id="navbarScroll">
              <Nav className="me-auto my-2 my-lg-0 nav-items" navbarScroll>
                <Nav.Link
                  onClick={toMyRequestsPage}
                  style={{ color: isActive("/") && isSentTab(location) ? "#141619" : "",
                  fontWeight: isActive("/") && isSentTab(location) ? "bold" : "" }}
                >
                  {user?.privilage === "ADMIN" ? t("Navbar.requests") : t("Navbar.sendRequests")}
                </Nav.Link>
                {user?.privilage === "USER" && <Nav.Link
                  onClick={toIncomingRequestsPage}
                  style={{ color: isActive("/") && isIncomingTab(location) ? "#141619" : "",
                  fontWeight: isActive("/") && isIncomingTab(location) ? "bold":"" }}
                >
                  {t("Navbar.incomingRequests")}
                </Nav.Link>}
                {user?.privilage === "ADMIN" && <Nav.Link
                  onClick={toPendingPage}
                  style={{ color: isActive("/user-pending") ? "#141619" : "",
                  fontWeight: isActive("/user-pending") ? "bold":"" }}
                >
                  {t("Navbar.pendingUsers")}
                </Nav.Link>}
                <Nav.Link
                  onClick={toPublicRequestsPage}
                  style={{ color: isActive("/") && isPublicTab(location)? "#141619" : "",
                  fontWeight: isActive("/") && isPublicTab(location)? "bold" : "" }}
                >
                  {t("Navbar.publicRequests")}
                </Nav.Link>
              </Nav>

              <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                <Tooltip title="Account settings">
                  <IconButton
                    onClick={handleMenuClick}
                    size="small"
                    sx={{ ml: 2 }}
                    aria-controls={menuAnchorEl ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={menuAnchorEl ? 'true' : undefined}
                  >
                    <Avatar sx={{ 
                      width: 50, 
                      height: 50, 
                      border: '2px solid #1976d2'  // Dark grey border for better visibility
                    }} src={selectedAvatar} />
                  </IconButton>
                </Tooltip>
              </Box>

              <Menu
                anchorEl={menuAnchorEl}
                open={Boolean(menuAnchorEl)}
                onClose={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                      width: 40,
                      height: 40,
                      ml: -0.5,
                      mr: 1,
                    },
                    '&::before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              >
                {user?.privilage === "ADMIN" && (
                  <NestedMenuItem
                    label={t("Navbar.downloadAsExcel")}
                    parentMenuOpen={Boolean(menuAnchorEl)}
                  >
                    <MenuItem onClick={downloadUsersDataAsExcel}>
                      {t("Navbar.usersData")}
                    </MenuItem>
                    <MenuItem onClick={downloadRequestsDataAsExcel}>
                      {t("Navbar.requestsData")}
                    </MenuItem>
                  </NestedMenuItem>
                )}
                <NestedMenuItem
                  label={("Language")}
                  parentMenuOpen={Boolean(menuAnchorEl)}
                >
                  <MenuItem onClick={() => 
                    {updateLanguage('en')}
                  }>
                    {("English")}
                  </MenuItem>
                  <MenuItem onClick={() => 
                    {updateLanguage('he')}
                  }>
                    {t("Hebrew")}
                  </MenuItem>
                  <MenuItem onClick={() => 
                    {updateLanguage('ar')}
                  }>
                    {t("Arabic")}
                  </MenuItem>
                </NestedMenuItem>
                <MenuItem onClick={handleAvatarClick}>
                  {"Avatar"}
                </MenuItem>
                <MenuItem onClick={() => navigate('/log-out')}>
                  <Logout fontSize="small" />
                  {t("Navbar.logout")}
                </MenuItem>
              </Menu>
            </ReactNavbar.Collapse>}
          </Container>
        </ReactNavbar>
      </div>
      <div style={{ height: height }}/>

      {/* Avatar Selection Dialog */}
      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>{"Select Avatar"}</DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
            {[avatar1, avatar2, avatar3, avatar4, avatar5].map((avatar, index) => (
              <IconButton key={index} onClick={() => handleAvatarSelect(avatar)}>
                <Avatar src={avatar} sx={{
                  width: 60,
                  height: 60,
                  border: tempAvatar === avatar ? '3px solid #1976d2' : 'none' // Highlight selected avatar
                }}/>
              </IconButton>
            ))}
          </Box>
        </DialogContent>
        <DialogActions>
        <Button 
  onClick={handleApplyAvatar} 
  sx={{ 
    color: 'white', 
    backgroundColor: '#1976d2', 
    borderRadius: '8px',  // Slightly round the edges
    padding: '8px 16px',  // Adjust padding to make it look balanced
    textTransform: 'none' // Keep the text case normal
  }}
>
  {t("Apply")}
</Button>

        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Navbar;
