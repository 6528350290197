import React, { useEffect, useState } from "react";
import { microServices, getMicroserviceUri } from '../../utils/uri.js';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from "@mui/material";
import { Container } from "react-bootstrap";
import ReplyAllSharpIcon from '@mui/icons-material/ReplyAllSharp';
import "../../App.css";
import { useDirection } from '../../Components/DirectionContext.js';
import { useTranslation } from 'react-i18next';
import { fetchWrapper } from "../../utils/util.js";
import { getLanguage } from "../../utils/userCookie.js";

async function fetchAllUsers() {
  let response;
  try {
    response = await fetchWrapper(getMicroserviceUri(microServices.USERS) + '/getAllUsers',
      'GET',
      {
        'Content-Type': 'application/json',
      },
    );
  } catch (e) {
    console.log("fetch error", e);
    return null; // Return null or handle error accordingly
  }
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  const data = await response.json();
  console.log(data);
  return data;
}

async function sendRequest(inputs, uri) {
  let response;
  try {
    response = await fetchWrapper(uri,
      'POST',
      {
        'Content-Type': 'application/json',
      },
      JSON.stringify(inputs),
    );
  } catch (e) {
    console.log("fetch error", e);
    return null; // Return null or handle error accordingly
  }
  if (!response.ok) {
    console.log("response:");
    console.log(response);
    console.log('Network response was not ok', response);
    return null; // Return null or handle error accordingly
  }
  const data = await response.json();
  console.log('Server response:', data);
  return data;
}

async function unblockUser(email_address) {
  const inputs = {
    email_address: email_address,
    blocked: false
  };
  const uri = getMicroserviceUri(microServices.USERS) + '/unblockUser';
  await sendRequest(inputs, uri);
}

async function updateStatus(stat, email_address) {
  const inputs = {
    email_address: email_address,
    approved: stat
  };
  const uri = getMicroserviceUri(microServices.USERS) + '/updateUserStatus';
  await sendRequest(inputs, uri);
}

function BlockedUsersPage() {
  const [users, setUsers] = useState([]);
  const [dependency, setDependency] = useState(0);
  const [selectedUser, setSelectedUser] = useState(null);
  const [open, setOpen] = useState(false);

  function triggerDependency() {
    setDependency(dependency + 1);
  }

  const fetchUsers = async () => {
    try {
      const data = await fetchAllUsers();
      // Filter users to include only those with blocked: true
      const blockedUsers = data.filter(user => user.blocked);
      setUsers(blockedUsers);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [dependency]);

  const handleUnblockClick = (user) => {
    setSelectedUser(user);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedUser(null);
  };

  const handleUnblock = async () => {
    if (selectedUser) {
      await unblockUser(selectedUser.email_address);
      await updateStatus(true, selectedUser.email_address); // Set approved to true
      triggerDependency();
      handleClose();
    }
  };

  const { t } = useTranslation();

  return (
    <div data-testid="blockedUsers">
      <Container style={{ direction: useDirection()?.direction, padding: 0, margin: 0 }}>
        <div style={{ overflowX: "auto", width: "100%", padding: 0, margin: 0 }}>
          <table 
            style={{
               width: "100%", 
               minWidth: "600px", 
               padding: 0, 
               margin: 0,
               direction: getLanguage() =='en'?'ltr':'rtl',
               
            }}
          >
            <thead>
              <tr>
                <th>{t("UserPending.name")}</th>
                <th>{t("UserPending.email")}</th>
                <th>{t("UserPending.organization")}</th>
                <th>{t("Other.Actions")}</th>
              </tr>
            </thead>
            <tbody>
              {users.map((row) => (
                <tr key={row._id}>
                  <td>{row.first_name + ' ' + row.last_name}</td>
                  <td>{row.email_address}</td>
                  <td>{row.orginization}</td>
                  <td>
                    <IconButton
                      style={{ color: "green" }}
                      onClick={() => { handleUnblockClick(row); }}
                    >
                      <ReplyAllSharpIcon />
                    </IconButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Container>
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>{"Confirm Unblock"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to unblock {selectedUser?.first_name} {selectedUser?.last_name}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleUnblock} color="secondary">
            Unblock
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default BlockedUsersPage;
