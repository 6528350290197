import React, { useTransition } from 'react';
import { Button, Form} from "react-bootstrap";
import {
  inputStyle,
  formSecondaryLabelStyle,
  textboxStyle,
  buttonStyle
} from '../JSStyles/SignInUpStyles.js';
import RichTextComponent from './RichTextComponent.js';
import { fetchWrapper } from '../utils/util.js';
import i18n from '../i18n.js';
import { withTranslation } from 'react-i18next';
class FormInnerComponent extends React.Component {
  containsInput() {
    return false;
  }
}
class FormHiddenComponent extends FormInnerComponent {
  constructor(props) {
    super(props);
    this.value = props.value;
    this._key = props._key;
  }

  containsInput() {
    return true;
  }

  render(){
    return (<div style={{
      display: "none"
    }}/>);
  }
}

class FormLabelWithComponent extends FormInnerComponent {
  constructor(props) {
    super(props);
    this.label = props.label;
  }
  updateValue = (element) => {

  }

  inputType() {
    return null;
  }
  inputElement() {
    return null;
  }
  placeholder() {
    return null;
  }
  style() {
    return null;
  }

  render() {
    return (
      <Form.Group>
        <Form.Label style={formSecondaryLabelStyle}>{this.label}</Form.Label>
        {this.containsInput() && this.inputElement()}
      </Form.Group>
    );
  }
}
class FormLabelInputComponent extends FormLabelWithComponent {
  constructor(props) {
    super(props);
    this.value = "";
    this._key = props._key;
  }

  containsInput() {
    return true;
  }
  inputElement() {
    return (
      <Form.Control
        type={this.inputType()}
        onChange={this.updateValue}
        required
        style={this.style()}
      />
    )
  }
  updateValue = (element) => {
    this.value = element.target.value;
  }
  directlyUpdateValue = (value) => {
    this.value = value;
  }

  inputType() {
    return "text";
  }
  placeholder() {
    return this.label;
  }
  style() {
    return inputStyle;
  }
}

class FormLabelEmailComponent extends FormLabelInputComponent {
  inputType() {
    return "email";
  }
  placeholder() {
    return "Email Address";
  }


}

class FormLabelPasswordComponent extends FormLabelInputComponent {

  inputType() {
    return "password";
  }
}


class FormLabelTxtAreaComponent extends FormLabelInputComponent {
  inputType() {
    return null;
  }
  placeholder() {
    return "";
  }
  style() {
    return textboxStyle;
  }
  inputElement() {
    return (
      <Form.Control
        as="textarea"
        rows={8}
        onChange={this.updateValue}
        required
        style={this.style()}
      />
    )
  }
}

class FormLabelRichTxtAreaComponent extends FormLabelInputComponent{
  inputType() {
    return null;
  }
  placeholder() {
    return "";
  }
  style() {
    return textboxStyle;
  }
  inputElement() {
    return (
      <RichTextComponent 
        value={this.value}
        onChange={this.directlyUpdateValue}
        style={this.style()}
      />
    )
  }
}

class FormButtonComponent extends FormInnerComponent {
  constructor(props) {
    super(props);
    this.label = props.label;
    this._key = props._key
  }
  updateValue = (element) => {
    this.value = element.target.value;
  }
  render() {
    return (
      <Button
        className="submit-group mt-3"
        style={buttonStyle}
        type="submit"
      >
        {this.label}

      </Button>
    );
  }
}

function getLanguage(lan){
  if (lan == 'en'){
    return 'en'
  }else if (lan == 'he'){
    return 'he'
  }else{
    return 'ar';
  }
}


class CustomForm extends React.Component {
  constructor(props) {
    super(props);
    this.innerComponents = () => {
      let res = []
      for(const component of this.innerComponentsRefs){
        if (!component.current){
          continue;
        }
        res = [...res, component.current]
      }
      return res;
    }
    this.innerComponentsRefs = []
    this.children = props.children;
    this.submitURI = props.submitURI;
    this.handleConnectionError = props.handleConnectionError;
    this.handleResponseOk = props.handleResponseOk;
    this.handleResponseNotOk = props.handleResponseNotOk;
    
  }

  createChildRef(){
    const ref = React.createRef();
    this.innerComponentsRefs = [...this.innerComponentsRefs, ref]
    return ref
  }

  submit = async (event) => {
    event.preventDefault();
  
    var inputs = {};
    this.innerComponents().filter((component) =>
      component.containsInput()
    ).forEach((component) => {
      inputs[component._key] = component.value;
      component.value = "";
    })
    try {
      const response = await fetchWrapper(this.submitURI, 
        'POST',
        {
          'Content-Type': 'application/json',
          // Add any other headers you might need
        },
        JSON.stringify(inputs),
      );

      if (!response.ok) {
        await this.handleResponseNotOk(response);
        return;
      }

      // Handle the response here
      await this.handleResponseOk(response);
      return;
    } catch (error) {
      await this.handleConnectionError(error);
      return;
    }
  }

  render() {
    return (
      <Form onSubmit={this.submit}>
        {this.children.map((item, index) => (
          <div key={index}>
            {React.cloneElement(item, {
              ref: this.createChildRef(),
            })}
          </div>
        ))}
      </Form>
    )
  }
}

export {
  CustomForm as Form,
  FormButtonComponent,
  FormLabelInputComponent,
  FormLabelEmailComponent,
  FormLabelTxtAreaComponent,
  FormLabelWithComponent,
  FormLabelPasswordComponent,
  FormHiddenComponent,
  FormLabelRichTxtAreaComponent
};
