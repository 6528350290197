import React from 'react';
import { Col, Container, Row } from "react-bootstrap";
import form_img from "../assets/form.svg";
import { getDirection } from '../utils/userCookie';

function StylizedFormLayout({ header, children, noPic}) {
  let addPic = true;
  if (noPic){
    addPic = false;
  }
  return (
    <div style={{display: "flex",
      flexDirection: "column",
      minHeight: "86.5vh",
      direction: getDirection()
    }}>
      <Container>
        <Row style={{ margin: "2rem 0 0.4rem 0" }}>
          <h2 className="heading" id="cars">
            {header}
          </h2>
          {addPic? <><Col lg={5} md={5} sm={6}>
            <img
              src={form_img}
              alt=""
              style={{ width: "75%", height: "85%" }}
            />
          </Col>
          <Col lg={7} md={7} sm={6}>
            {children}
          </Col></>
          :<>{children}</>}
        </Row>
      </Container>
      <></>
      <div style={{flex: 1}}></div>
      <div style={{left: 0, width: "100%" }}>
        <div className="intro-svg">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
            <path
              fill="#C6D5E9"
              fillOpacity="1"
              d="M0,32L48,58.7C96,85,192,139,288,181.3C384,224,480,256,576,240C672,224,768,160,864,122.7C960,85,1056,75,1152,96C1248,117,1344,171,1392,197.3L1440,224L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
            ></path>
          </svg>
        </div>
      </div>
    </div>
  );
}

export default StylizedFormLayout;