// import Keycloak from 'keycloak-js';
import { setUser } from './userCookie';

import { useEffect, useState } from 'react';

import { useAuth } from 'react-oidc-context';

let _oidc;
export const handleLogin = () => {
    console.log("now");
    _oidc.signinRedirect();
};

export const handleLogout = () => {
    _oidc.signoutRedirect();
};

export const handleRefreshToken = async () => {
    function sleep(ms) {
        return new Promise((resolve) => setTimeout(resolve, ms));
      }
    try {
        await _oidc?.signinSilent();
        console.log('Token refreshed successfully');
        await sleep(100);
        return true;
    } catch (error) {
        console.error('Failed to refresh token:', error);
        // Handle token refresh rejection gracefully, e.g., redirect to login
        return false;
    }
};

export function useKC() {
    _oidc = useAuth();
}

export function getToken(){
    return _oidc?.user?.access_token;
}

let _setIsUnauthorized = null;
export function useIsUnauthorized() {
    const [isUnauthorized, setIsUnauthorized] = useState(false);
    _setIsUnauthorized = setIsUnauthorized;
    useEffect(() => {
        if (isUnauthorized === false) {
            return;
        }
        setUser(null);
        _oidc.signoutRedirect();
    }, [isUnauthorized])
}
export function triggerUnauthorized() {
    _setIsUnauthorized && _setIsUnauthorized(true);
}

export function getKCAuthed(){
    return _oidc.isAuthenticated;
}

export function getKCIsLoading(){
    return _oidc.isLoading;
}