import React, { useState, useEffect, useRef } from "react";
import { Container, Card } from "react-bootstrap";
import TableControls from "../Components/TableControls";
import { useNavigate } from "react-router-dom";
import { 
  updateRequestTags, 
  UpdateRequestStatus,
  getRequests, 
  assignHandler, 
  getAllUsers, 
  getPlainTextFromHtml,
  updateRequestPublicity,
  getPublicRequests,
} from '../utils/util.js';
import { getUser } from "../utils/userCookie.js";
import { useLocation } from "react-router-dom";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import { Tooltip } from '@mui/material';
import { CheckCircle, Cancel, HourglassEmpty, DoubleArrow } from '@mui/icons-material';
import ForumIcon from '@mui/icons-material/Forum';
import AddIcon from '@mui/icons-material/Add';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import './CustomTable.css'; // Import the CSS files
import { useDirection } from '../Components/DirectionContext.js';
import { useTranslation } from 'react-i18next';
import Tag from '../Components/Tag.js';

const statusStyles = {
  PENDING: { color: 'orange' },
  DONE: { color: 'green' },
  IN_PROGRESS: { color: 'blue' },
  CLOSE: { color: 'grey' }
};

const statusIcons = {
  PENDING: <HourglassEmpty className="hourglass-animated" style={{ color: 'orange', fontSize: '20px' }} />,
  DONE: <CheckCircle style={{ color: 'green' }} />,
  REJECTED: <Cancel style={{ color: 'red' }} />,
  IN_PROGRESS: <DoubleArrow className="progress-animated" style={{ color: 'blue', fontSize: '20px' }} />,
  CLOSE: <CheckCircle style={{ color: 'grey' }} />
};

const formatDate = (dateString) => {
  const date = new Date(dateString);
  return new Intl.DateTimeFormat('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
  }).format(date);
};

const getRandomColor = () => {
  const letters = '01234567'; // Lower range values to ensure darkness
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * letters.length)];
  }
  return color;
};


const CustomTable = () => {
  const [editingRowId, setEditingRowId] = useState(null);
  const [statusEdit, setStatusEdit] = useState(null);
  const [requests, setRequests] = useState([]);
  const [users, setUsers] = useState([]);
  const [autocompleteOptions, setAutocompleteOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [statusInputValue, setStatusInputValue] = useState('');
  const [showInput, setShowInput] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const autocompleteRef = useRef(null);
  const tagsColors = useRef({})
  function getTagColor(tag){
    if (tag in tagsColors.current){
      return tagsColors.current[tag]
    }else{;
      const newColor = getRandomColor();
      const newColors = {...tagsColors.current}
      newColors[tag] = newColor;
      tagsColors.current = newColors;
      return newColor;
    }
  }

  const updateRequests = async () => {
    await updateRequestsBySearch("");
  };

  const updateRequestsBySearch = async (searchVal) => {
    const isMyRequests = getIsMyRequest();
    const reqs = await getPublicRequests();
    reqs.forEach(req => {
      if (req.tags) {
        req.tags = req.tags.map(tag => ({
          text: tag,
          color: getTagColor(tag)
        }));
      }
    });
    setRequests(reqs);
  }

  const updateUsers = async () => {
    if (getUser()?.privilage === "ADMIN"){
      const temp = await getAllUsers();
      const temp2 = temp.map((user, index)=>{
        return {
          id: index,
          name: `${user.first_name} ${user.last_name}`,
          email: user.email_address
        }
      })
      setUsers(temp2);
      setAutocompleteOptions(temp2.map((value)=>value.id));
    }
  };

  const getIsMyRequest = () => {
    return location.state?.isMyRequests ?? true;
  };

  const handleEditClick = (event, rowId) => {
    event.stopPropagation();
    setEditingRowId(rowId);
    setInputValue('');  // Clear input value when starting to edit
    setAutocompleteOptions(users.map((user) => user.id));
  };

  const handleStatusEditClick = (event, rowId) => {
    event.stopPropagation();
    setStatusEdit(rowId);
    setStatusInputValue('');  // Clear input value when starting to edit
  };

  const handleAssignHandlerAndUnfocusAutocomplete = async (row, newValue) => {
    try {
      await assignHandler(row._id, newValue);
      await updateRequests(); // Refresh requests to reflect changes

    } catch (error) {
      console.error('Error assigning handler:', error);
    } finally {
      unfocusAutocomplete();
    }
  };

  const handleStatusChangeAndUnfocusAutocomplete = async (row, newValue) => {
    try {
      await UpdateRequestStatus(row._id, newValue);
      await updateRequests(); // Refresh requests to reflect changes
      setStatusEdit(null);

    } catch (error) {
      console.error('Error assigning handler:', error);
    } finally {
      setStatusEdit(null);
    }
  };

  const handleInputChange = (event, newValue) => {
    setInputValue(newValue);
    const filteredUsers = users.filter((user) => `${user.name} ${user.email}`.includes(newValue));
    setAutocompleteOptions(filteredUsers.map((user) => user.id));
  };

  const unfocusAutocomplete = () => {
    setEditingRowId(null);
    setStatusEdit(null);
  };

  useEffect(() => {
    updateRequests();
    updateUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const toPendingPage = () => {
    navigate('/new-request');
  };

  const addTag = async (target, code, value, id, tags) => {
    if (code === 'Enter') {
      const tagName = value.trim(); 
      const newTag = { text:tagName, color: getTagColor(tagName) };
      tags.push(newTag);
      target.value = "";
      setShowInput(null);
      await updateRequestTags(id, tags.map(tag => tag.text));  // Only store the text of the tags
      updateRequests();
    }
  };

  const handleAddTagClick = (event, rowId) => {
    event.stopPropagation();
    setShowInput(rowId);
  };

  const getStatusTranslation = (status) => {
    switch(status){
      case "PENDING":
        return 'CustomTable.pending'
      case "IN_PROGRESS":
        return 'CustomTable.inProgress'
      case "DONE":
        return 'CustomTable.done'
      default:
        return ''
    }
  }

  const getInputFormat = (index) => {
    const user = users.filter((value) => value.id === index)[0];
    return `${user.name} ${user.email}`;
  }

  const getEmailById = (value) => {
    const user = users.filter((v) => v.id === value)[0];
    return user.email;
  }

  const truncateIfNeeded = (str) => {
    const maxLength = 150;
    return str.length > maxLength ? str.substring(0, maxLength) + '...' : str;
  };

  const {t} = useTranslation();

  return (
    <div
    onClick={(event) => {unfocusAutocomplete(event)}} data-testid="customTable">
      <TableControls onAddClick={toPendingPage} setRequests={updateRequestsBySearch} />
      <Container>
        <Card>
          <Card.Body>
            <div className="col-xs-8 col-xs-offset-2 well">
              <div style={{ direction: useDirection()?.direction }}>
                <table className="scrollable" >
                  <thead>
                    <tr>
                      {
                        getUser()?.privilage === "ADMIN" && 
                        <th className="set-public-header">{t('CustomTable.public')}</th>
                      }
                      <th>{t('CustomTable.dateAndStatus')}</th>
                      {(getUser()?.privilage === "ADMIN" || !getIsMyRequest()) && <th>{t('CustomTable.inquirer')}</th>}
                      {(getUser()?.privilage === "ADMIN" || getIsMyRequest()) && <th className="request-handler-header">{t('CustomTable.handler')}</th>}
                      {(getUser()?.privilage === "ADMIN" || getIsMyRequest()) && <th className="request-icons"></th>}
                      <th className="request-content-header">{t('CustomTable.requestContent')}</th>
                      <th className="request-icons"></th>
                      <th>{t('CustomTable.tags')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {requests.map((row) => (
                      <tr key={row._id} tabIndex="0">
                        {getUser()?.privilage === "ADMIN" && (
                          <td className="set-public-column">
                            <PublicRequestCheckbox id={row._id} initial_status={row.public} />
                          </td>
                        )}
                        <td>
                          <Tooltip title={row.createdAt}>
                            <span style={{ display: 'block', color: statusStyles[row.status].color, marginBottom: '4px', paddingLeft: 'px' }}>
                              {formatDate(row.createdAt)}
                            </span>
                          </Tooltip>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            {statusEdit === row._id ? (
                            <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Status</InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={statusInputValue}
                              label="Status"
                              onChange={async (event) => {
                                event.stopPropagation();
                                setStatusInputValue(event.target.value);
                                await handleStatusChangeAndUnfocusAutocomplete(row, event.target.value);
                                updateRequests();
                              }}
                            >
                              { row.status==="DONE" && <MenuItem value={"IN_PROGRESS"}>IN_PROGRESS</MenuItem>}
                              { row.status==="IN_PROGRESS" && <MenuItem value={"DONE"}>DONE</MenuItem>}
                            </Select>
                          </FormControl>
                          ):(['PENDING', 'IN_PROGRESS'].includes(row.status) ? (
                              <>
                                {statusIcons[row.status]}
                                <span style={{ marginLeft: '2px', ...statusStyles[row.status] }}>{t(getStatusTranslation(row.status))}</span>
                                {getUser()?.privilage === "ADMIN" && (
                                <IconButton onClick={(event) => handleStatusEditClick(event, row._id)}>
                                  <EditIcon />
                                </IconButton>
                              )}
                              </>
                            ) : (
                              <>
                                <span style={{ marginRight: '2px', ...statusStyles[row.status] }}>{t(getStatusTranslation(row.status))}</span>
                                {statusIcons[row.status]}
                                {getUser()?.privilage === "ADMIN" && (
                                <IconButton onClick={(event) => handleStatusEditClick(event, row._id)}>
                                  <EditIcon />
                                </IconButton>
                              )}
                              </>
                            ))}
                          </div>
                        </td>
                        {(getUser()?.privilage === "ADMIN" || !getIsMyRequest()) && 
                        <td>
                          <div>
                              <Tooltip title={row.user_id ? `${row.user_id.email_address}` : ""} placement="top">
                                {row.user_id ? `${row.user_id.first_name} ${row.user_id.last_name}` : ""}
                              </Tooltip>
                            </div>
                        </td>
                        }
                        <td>
                          {editingRowId === row._id ? (
                            <div ref={autocompleteRef} className="handler-autocomplete" style={{ width: '100%', maxWidth: '200px' }}>
                              <Autocomplete
                                id="handler-autocomplete"
                                freeSolo
                                filterOptions={(x) => x}
                                getOptionLabel={(option) => getInputFormat(option) }
                                componentsProps={{ paper: { sx: { width: 250 } } }}
                                options={autocompleteOptions}
                                renderOption={(props, option) => (
                                  <li {...props} onClick={(event) => {
                                    event.stopPropagation();
                                    props.onClick(event);
                                  }}>
                                    {getInputFormat(option)}
                                  </li>
                                )}
                                inputValue={inputValue}
                                onInputChange={handleInputChange}
                                onChange={async (event, newValue) => {
                                  await handleAssignHandlerAndUnfocusAutocomplete(row, getEmailById(newValue));
                                  updateRequests();
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    onClick={async(event) => {
                                      event.stopPropagation();
                                      await assignHandler(row, params);
                                      updateRequests();

                                    }}
                                    placeholder={t("CustomTable.assignHandler")}
                                    autoFocus
                                    sx={{
                                      width: '100%',
                                      maxWidth: '200px', // Ensure the width fits within the column
                                      backgroundColor: 'white',
                                      boxShadow: 3,
                                      borderRadius: 1,
                                      '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                          border: 'none',
                                        },
                                      },
                                    }}
                                  />
                                )}
                                sx={{
                                  width: '100%',
                                  maxWidth: '200px', // Ensure the width fits within the column
                                  backgroundColor: 'white',
                                  boxShadow: 3,
                                  borderRadius: 1,
                                  '& .MuiAutocomplete-inputRoot': {
                                    padding: '8px',
                                    fontSize: '14px',
                                  },
                                  '& .MuiAutocomplete-option': {
                                    fontSize: '14px',
                                  },
                                }}
                              />
                            </div>
                          ) : (
                            <div>
                              <Tooltip title={row.handler_id ? `${row.handler_id.email_address}` : ""} placement="top">
                                {row.handler_id ? `${row.handler_id.first_name} ${row.handler_id.last_name}` : ""}
                              </Tooltip>    
                          </div>
                          )}
                        </td>
                        <td>
                          {getUser()?.privilage === "ADMIN" && (
                            <IconButton onClick={(event) => handleEditClick(event, row._id)}>
                            <EditIcon />
                            </IconButton>
                          )}
                        </td>
                        <td>
                              <strong >{row.subject}</strong>
                              <br />
                              {truncateIfNeeded(getPlainTextFromHtml(row.info))}
                        </td>
                        <td>
                          <Tooltip title={t("CustomTable.Go to Messages")} placement="top">
                            <IconButton aria-label="Example" onClick={() => navigate('/reply-page', { state: row })}>
                              <ForumIcon style={{ color: 'green' }} />
                            </IconButton>
                          </Tooltip>
                        </td>
                        <td>
                          <div className="tag-container">
                            {row.tags && row.tags.map((tag, index) => (
                            <Tag
                                Tag key={index} 
                                tag={tag} 
                                requestId={row._id} 
                                color={tag.color || getRandomColor()} 
                                updateFunction={updateRequests}
                            />
                            ))}
                            
                            {getUser()?.privilage === "ADMIN" && (
                              <>
                                {showInput === row._id ? (
                                  <input
                                    type="text"
                                    id={`input-${row._id}`}
                                    placeholder={t("CustomTable.addTag")}
                                    className="add-tag-input"
                                    onClick={(event) => event.stopPropagation()}
                                    onKeyDown={(event) => addTag(event.target, event.code, event.target.value, row._id, row.tags)}
                                  />
                                ) : (
                                  <button className="add-tag-button" onClick={(event) => handleAddTagClick(event, row._id)}>
                                    <AddIcon />
                                  </button>
                                )}
                              </>
                            )}
                          </div>
                        </td>
                        
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
};

/* START Code for setPublic checkbox. AFTER MERGE we can organise the code*/
/* updateRequestPublicity To utils*/



function PublicRequestCheckbox({ id, initial_status }) {
  const [isChecked, setIsChecked] = useState(false);

  async function fetchAndUpdateStatus(id, newStatus) {
    if (newStatus !== undefined) {
      // Logic to update the status on the server
      console.log(`Updating status to: ${newStatus}`);
      updateRequestPublicity(id, newStatus)
      return newStatus;
    } else {
      console.log(`Initial status: ${initial_status}`);
      return initial_status;
    }
  }
  // Fetch initial checked status on mount
  useEffect(() => {
    const initializeStatus = async () => {
      const initialStatus = await fetchAndUpdateStatus();
      setIsChecked(initialStatus);
    };
    initializeStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = async (event) => {
    event.stopPropagation();
    const newStatus = event.target.checked;
    setIsChecked(newStatus);
    await fetchAndUpdateStatus(id, newStatus);
  };

  return (
    <input
      type="checkbox"
      checked={isChecked}
      onChange={handleChange}
      onClick={(event) => { event.stopPropagation() }}
      style={{ width: '24px', height: '24px', cursor: 'pointer', }}
    />
  );
}
/* END Code for setPublic checkbox*/

export default CustomTable;