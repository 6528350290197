import { getMicroserviceUri, microServices } from './uri.js'
// Fetchs the requests based on the user and filter based on the search field if neccesarry

import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz'

import { convert } from 'html-to-text';
import { getToken, handleRefreshToken, triggerUnauthorized } from './keycloakUtils.js';
import { customTableTabs } from './customTableTabsUtils.js';
import { doLoading } from './loadingUtils.js';
import { getLanguage } from './userCookie.js';



export async function fetchWrapper(uri, method, headers = {}, body = null) {
  async function func() {
    const refreshed = await handleRefreshToken(); // Check if token will expire in 5 seconds
    if (!refreshed) {
      triggerUnauthorized();
      await new Promise(() => { });
    }

    const params = {
      method: method,
      headers: {
        ...headers,
        'Authorization': `${getToken()}`
      }
    }

    if (body) {
      params['body'] = body;
    }

    const res = await fetch(uri, params);
    const unauthorizedHttpStatus = 401;
    if (res.status === unauthorizedHttpStatus) {
      console.log("found2");
      triggerUnauthorized();
      await new Promise(() => { });
      return res;
    }
    return res;
  }
  return await doLoading(func);
}

export async function getUser() {
  const response = await fetchWrapper(getMicroserviceUri(microServices.USERS) + '/checkCredentials',
    'POST',
    {
      'Content-Type': 'application/json',
    },
    JSON.stringify({}),
  );
  if (response.ok) {
    return await response.json();
  } else {
    return null;
  }

}

export async function getUserEmailAddressByToken() {
  const response = await fetchWrapper(getMicroserviceUri(microServices.USERS) + '/getUserEmailAddressByToken',
    'POST',
    {
      'Content-Type': 'application/json',
    },
    JSON.stringify({}),
  );
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  const res = await response.json();
  return res.emailAddress;
}

export async function isUserRegisteredAndApproved() {

  const response = await fetchWrapper(getMicroserviceUri(microServices.USERS) + '/isUserRegisteredAndApproved',
    'POST',
    {
      'Content-Type': 'application/json',
      // Add any other headers you might need
    },
    JSON.stringify({}),
  );
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  return await response.json();

}
export async function getRequests(current_user, tab, searchVal = "") {
  if (customTableTabs.public === tab) {
    return await getPublicRequests(searchVal);
  } else {
    return await getRequestsAux(current_user, tab === customTableTabs.sent, searchVal);
  }
}
async function getRequestsAux(current_user, isMyRequests, searchVal = "") {
  const res = await fetchWrapper(
    getMicroserviceUri(microServices.REQUESTS) + '/getRequests/' + current_user?._id + '/' + current_user?.privilage + '/' + isMyRequests,
    'GET',
  )
  const reqs = await res.json()
  return reqs.filter((row) =>
    row.subject.toLowerCase().includes(searchVal)
    || row.info.toLowerCase().includes(searchVal)
    || row.tags.join(', ').toLowerCase().includes(searchVal)
    || row.status.toLowerCase().includes(searchVal));
}

export function updateRequestPublicity(id, newStatus) {

  fetchWrapper(getMicroserviceUri(microServices.REQUESTS) + '/updateRequestPublicity',
    'PUT',
    {
      'Content-Type': 'application/json'
    },
    JSON.stringify({
      id: id,
      publicity: newStatus
    })
  )
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      console.log('Success:', data);
    })
    .catch(error => {
      console.error('Error:', error);
    });
}

export async function fetchPendingUsers() {
  const inputs = {
    approved: false
  };
  var response;
  try {
    response = await fetchWrapper(getMicroserviceUri(microServices.USERS) + '/fetchUsersByStatus',
      'POST',
      {
        'Content-Type': 'application/json',
        // Add any other headers you might need
      },
      JSON.stringify(inputs),
    );
  } catch (e) {
    console.log("fetch error", e);
  }
  if (!response.ok) {
    throw new Error('Network response was not ok ');
  }
  const data = await response.json();
  console.log(data);
  return data;

}

export async function getAllUsers() {
  const res = await fetchWrapper(getMicroserviceUri(microServices.USERS) + '/getAllUsers/',
    "GET",
  )
  return await res.json();
}

// Fetchs all public requests
export async function getPublicRequests(searchVal = "") {
  const res = await fetchWrapper(getMicroserviceUri(microServices.REQUESTS) + '/getPublicRequests/',
    "GET",
  )
  const reqs = await res.json()
  return reqs?.filter((row) =>
    row.subject.toLowerCase().includes(searchVal)
    || row.info.toLowerCase().includes(searchVal)
    || row.tags.join(', ').toLowerCase().includes(searchVal)
    || row.status.toLowerCase().includes(searchVal));
}


export async function createMessage(request_id, sender_id, info) {
  // Later we don't need privilage when we add user microservice.
  try {
    console.log({
      request_id: request_id,
      sender_id: sender_id,
      info: info
    }
    );
    const reply = await fetchWrapper(getMicroserviceUri(microServices.MESSAGES) + '/createMessage/',
      "POST",
      {
        'Content-Type': 'application/json',
        // Add any other headers you might need
      },
      JSON.stringify({
        request_id: request_id,
        sender_id: sender_id,
        info: info
      }
      )
    );
    if (reply.ok) {
      return await reply.json();
    } else {
      console.error(reply)
      return null;
    }
  } catch (err) {
    console.error(err);
    return null;
  }
}


export async function getRequestMessages(request_id) {
  try {
    const reply = await fetchWrapper(getMicroserviceUri(microServices.MESSAGES) + '/getRequestMessages/' + request_id, 'GET');
    if (reply.ok) {
      return await reply.json();
    } else {
      console.error(reply)
      return null;
    }
  } catch (err) {
    console.error(err);
    return null;
  }
}

export async function updateRequestTags(id, tags) {
  await fetchWrapper(getMicroserviceUri(microServices.REQUESTS) + '/updateRequestTags',
    "PUT",
    {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    JSON.stringify({ id, tags }),
  );
}

export async function assignHandler(request_id, handler_email) {
  try {
    const reply = await fetchWrapper(getMicroserviceUri(microServices.REQUESTS) + '/assignHandler',
      "POST",
      {
        'Content-Type': 'application/json',
        // Add any other headers you might need
      },
      JSON.stringify({
        request_id: request_id,
        handler_email: handler_email,
      })
    );
    if (reply.ok) {
      return await reply.json();
    } else {
      console.error(reply)
      return null;
    }
  } catch (err) {
    console.error(err);
    return null;
  }
}

export async function UpdateRequestStatus(request_id, status) {
  try {
    const reply = await fetchWrapper(getMicroserviceUri(microServices.REQUESTS) + '/updateRequestStatus',
      "PUT",
      {
        'Content-Type': 'application/json',
        // Add any other headers you might need
      },
      JSON.stringify({
        id: request_id,
        status: status,
      })
    );
    if (reply.ok) {
      return await reply.json();
    } else {
      console.error(reply)
      return null;
    }
  } catch (err) {
    console.error(err);
    return null;
  }
}

export async function addUser(user) {
  try {
    console.log(user);
    const reply = await fetchWrapper(getMicroserviceUri(microServices.USERS) + '/addUser/',
      "POST",
      {
        'Content-Type': 'application/json',
        // Add any other headers you might need
      },
      JSON.stringify(user)
    );
    if (reply.ok) {
      return reply;
    } else {
      console.error(reply)
      return null;
    }
  } catch (err) {
    console.error(err);
    return null;
  }
}


export async function downloadUsersDataAsExcel() {
  //TODO: in production, need to validate that the user is an admin
  //get the information as excel file
  try {
    const reply = await fetchWrapper(getMicroserviceUri(microServices.USERS) + '/downloadUsersDataAsExcel/',
      "GET",
    );
    if (reply.ok) {
      await reply.blob().then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'users_data.xlsx';
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
      });
    } else {
      console.error(reply)
      return null;
    }
  } catch (err) {
    console.error(err);
    return null;
  }
}

export async function downloadRequestsDataAsExcel() {
  //TODO: in production, need to validate that the user is an admin
  //get the information as excel file
  try {
    const reply = await fetchWrapper(getMicroserviceUri(microServices.REQUESTS) + '/downloadRequestsDataAsExcel',
      "GET",
    );
    if (reply.ok) {
      await reply.blob().then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'requests_data.xlsx';
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
      });
    } else {
      console.error(reply)
      return null;
    }
  } catch (err) {
    console.error(err);
    return null;
  }
}

// Function to format MongoDB timestamp to Israel time
export const formatTimestamp = (isoString) => {
  const israelTimeZone = 'Asia/Jerusalem';
  const zonedDate = utcToZonedTime(isoString, israelTimeZone);
  return format(zonedDate, 'dd/MM/yyyy HH:mm');
};


export function getUserAbbrevName(firstName, lastName) {
  return firstName + ' ' + lastName;
}

export function getPlainTextFromHtml(html) {
  return convert(html);
}


export const keycloakConfig = {
  url: process.env.REACT_APP_KC_AUTH_URI,
  realm: process.env.REACT_APP_KC_REALM,
  clientId: process.env.REACT_APP_KC_CLIENT,
};

// OnDelete Of a tag ("text of the tag") of a specific request
export const deleteTag = async (requestId, tagText) => {
  try {
    const response = await fetchWrapper(getMicroserviceUri(microServices.REQUESTS) + '/deleteTag',
      'DELETE',
      {
        'Content-Type': 'application/json',
      },
      JSON.stringify({
        requestId,
        tagText,
      })
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Failed to delete tag:', error);
    throw error;  // Re-throw the error if you need further error handling in the component
  }
}

export function getAlignment() {
  if (getLanguage() == 'en') {
    return 'left-align-table';
  } else {
    return 'right-align-table';
  }
}