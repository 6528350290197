import { useRef } from 'react';

export const useCriticalSection = () => {
  const lock = useRef(Promise.resolve()); // Initially resolved promise

  const enterCriticalSection = async (criticalFunction) => {
    let release;
    const waitForTurn = new Promise((resolve) => {
      release = resolve;
    });

    const previousLock = lock.current;

    // Chain the new lock to the previous one
    lock.current = previousLock.then(async () => {
      await waitForTurn;
      try {
        await criticalFunction();
      } finally {
        release(); // Allow the next in line to proceed
      }
    });

    // Release the lock to allow execution
    release();
  };

  return enterCriticalSection;
};