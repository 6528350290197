import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, useNavigate, useLoaderData } from "react-router-dom";
import RequestPage from "./Pages/RequestPage/RequestPage.js";
import BasicTabs from "./Pages/UsersPendingPage/BasicTabs.js";
import CustomTable from './Pages/CustomTable.js';
import PublicCustomTable from './Pages/PublicCustomTable.js';
import SignUp from './Pages/SignUp.js'
import Login from "./Pages/Login.js";
import ReplyPage from "./Pages/ReplayPage/ReplyPage.js"; // Adjust the path as necessary

import { getKCIsLoading, triggerUnauthorized, useIsUnauthorized, useKC } from './utils/keycloakUtils.js'

import Navbar from './Components/Navbar.js';

import {
  isUserDefined,
  useSetUpUserCookie,
} from './utils/userCookie.js'
import { LoadingComponent } from './Components/LoadingComponent.js';
import { useLoading } from './utils/loadingUtils.js';

function LogOut() {
  useEffect(() => {
    triggerUnauthorized();
  }, [])

  return <p>Loading...</p>
}

function App() {
  useKC();
  useIsUnauthorized();
  useSetUpUserCookie();
  useLoading();
  return (
    <LoadingComponent>
      <BrowserRouter>
        {isUserDefined() ? (
          <>
            <Navbar /> {/* Move Navbar here */}
            <Routes>
              <Route path='/' element={<CustomTable />} />
              <Route path="/new-request" element={<RequestPage />} />
              <Route path="/user-pending" element={<BasicTabs />} />
              <Route path='/sign-up' element={<SignUp />} />
              <Route path='/log-out' element={<LogOut />}></Route>
              <Route path="/reply-page" element={<ReplyPage />} />
              <Route path='/*' element={<RedirectHome />} />
            </Routes>
          </>
        ) : (
          <Routes>
            <Route path='/sign-up' element={<SignUp />} />
            <Route path='/log-out' element={<LogOut />}></Route>
            <Route path='/' element={<Login />} />
            <Route path='/*' element={<RedirectHome />} />
          </Routes>
        )}
      </BrowserRouter>
    </LoadingComponent>
  );
}

function RedirectHome() {
  const navigate = useNavigate();
  useEffect(() => {
    navigate('/');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export default App;
