import React, { useEffect, useState } from "react";
import { getUser } from '../../utils/userCookie.js';
import { useNavigate } from "react-router-dom";

import {
  Form,
  FormButtonComponent,
  FormLabelInputComponent,
  FormHiddenComponent,
  FormLabelRichTxtAreaComponent
} from "../../Components/Form.js";
import StylizedFormLayout from "../../Components/StylizedFormLayout.js";

import { getMicroserviceUri, microServices } from '../../utils/uri.js'
import { useTranslation } from "react-i18next";
import i18n from "../../i18n.js";
import i18next from "i18next";
import { useForceRerenderKey } from "../../utils/forceRerenderUponHookChange.js";
function RequestPage() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [count, setCount] = useState(0);

  useEffect(() => {
    forceRerenderDiv();
  },[i18n.language])




  const forceRerenderDiv = () => {
    setCount(prevCount => prevCount + 1); // This will trigger a rerender
  };
  return (
    <main data-testid="RequestPage" key={useForceRerenderKey(i18n.language)}>

      <StylizedFormLayout header={t('RequestPage.Request Page')}>
        <Form
          submitURI={getMicroserviceUri(microServices.REQUESTS) + '/createRequest'}
          handleResponseNotOk={(response) => {
            console.error(response);
          }}
          handleResponseOk={async (response) => {
            navigate('/');
          }}
          handleConnectionError={(error) => {
            console.error('Error:', error);
          }}
        >
          <FormHiddenComponent
            _key="user_id"
            value={getUser()?._id}
          />
          <FormLabelInputComponent
            _key="subject"
            label={t("RequestPage.Subject")}
          />
          <FormLabelRichTxtAreaComponent
            _key="info"
            label={t("RequestPage.Description")}
          />
          <FormButtonComponent
            label="send"
          />
        </Form>
      </StylizedFormLayout>

    </main>
  );


}

export default RequestPage;
