import React from 'react';
import { deleteTag } from '../utils/util.js';
import { getUser } from "../utils/userCookie.js";
import RedXImg from '../assets/red_x.png';
import { useState } from 'react';
import { Box, Typography } from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";

const Tag = ({ tag, requestId, color, updateFunction }) => {
    // "updateFunction" is the function that refreshes the page and shows
    // the new page after deleting the tag.



  const style = {
    backgroundColor: color || '#ccc',
    cursor: 'pointer',
    // opacity: isHovered ? 0.5 : 1,
    // transition: 'opacity 0.2s',
    position: 'relative'
  };


  const handleDelete = async (e) => {
    e.stopPropagation();
    async function func(){
      await deleteTag(requestId, tag.text);
      await updateFunction();
    }
    await func();
  };

  

  // return (
  //   <div 
  //     className="tag" 
  //     style={style}
  //     onMouseEnter={handleMouseEnter}
  //     onMouseLeave={handleMouseLeave}
  //     onDoubleClick={getUser()?.privilage === "ADMIN" ? handleDelete : undefined}
  //     onClick={(e) => e.stopPropagation()}
  //     onMouseUp={(e) => e.stopPropagation()}
  //   >
  //     {tag.text}
  //     {isHovered && (
  //       <img 
  //         src={RedXImg} 
  //         alt="Delete" 
  //         style={{
  //           opacity: 1,
  //           position: 'absolute',
  //           top: '10%',
  //           right: '10px',
  //           width: '20px',
  //           height: '20px',
  //           // transform: 'translate(-100%, -50%)',
  //           pointerEvents: 'none'
  //         }}
  //       />
  //     )}
  //   </div>
  // );


  return (
    <Box
      onClick={getUser()?.privilage === "ADMIN" ? handleDelete : undefined}
      sx={{
        borderRadius: '15px',
        backgroundColor: color,
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: "auto 1px",
        '&:hover .hover-text': {
          opacity: 1,
        },
      }}
    >
      <Typography 
        variant="div" 
        component="div" 
        className="tag"
        style={style}
      >
        {tag.text}
      </Typography>
      {getUser()?.privilage === "ADMIN" && <Box
        className="hover-text"
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          borderRadius: '15px',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          opacity: 0,
          transition: 'opacity 0.3s ease',
        }}
      >
        <Typography variant="h6" color="white">
          X
        </Typography>
      </Box>}
    </Box>
  );
};


export default Tag;