// const backendUri = 'http://localhost:';
// const backendPrefix = '/api/service'

// const microServices = {USERS: '1', REQUESTS: '2', MESSAGES:'3'};

// function getMicroserviceUri(microservice){
//     const microServiceNum = microservice;
//     return backendUri + 300 + microServiceNum + backendPrefix + microServiceNum
// }

// export {microServices, getMicroserviceUri};

const backendUri = 'https://pashoot.techniondev.ybh.dev';
const backendPrefix = '/api/service'

const microServices = {USERS: '1', REQUESTS: '2', MESSAGES:'3'};

function getMicroserviceUri(microservice){
    const microServiceNum = microservice;
    return backendUri + backendPrefix + microServiceNum;
}

export {microServices, getMicroserviceUri};
