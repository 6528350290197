const formPageMainStyle = {
  margin: ' 0.65vw 0.65vw 0 0',
  padding: '0',
  width: '100%',
  height: '100vh',
  boxSizing: 'border-box',
};
const pageTitleStyle = {
  color: 'rgb(25, 131, 183)',
  fontSize: '3.1vw',
  width: 'fit-content',
  margin: '0.98vw',
  padding: '0',
  display: 'block',
  marginBlockStart: "0.83em",
  marginBlockEnd: "0.83em",
  marginInlineStart: '0',
  marginInlineEnd: '0',
  fontWeight: 'bold'
};

const formContainerStyle = {
  boxShadow: "0.5vw 0.5vw #9b9b9b",
  borderStyle: "solid",
  borderRadius: "2.5vw",
  borderWidth: "0.25vw",
  padding: "0 2vw 2vw 1.5vw",
  margin: "1vw auto auto auto",
  borderColor: "black",
  display: "block"
};

const formStyle = {
  display: "inline-block",
  margin: 0,
  padding: 0
};

const inputStyle = {
  border: "2px solid var(--lightblue)" 
};
const formSecondaryLabelStyle = {
  fontSize: "22px",
  fontWeight: "600",
  color: "var(--grey)",
  fontFamily: "Nunito"
}
const tinySpaceBetweenLastInputAndMessageStyle = {
  display: "inline-block",
  margin: "auto 1vw"
};

const buttonStyle = {
  backgroundColor: "var(--seablue)",
  fontWeight: "600",
  border: "2px solid var(--seablue)",
  padding: "15px",
  fontFamily: "Nunito",
  color: "var(--grey)"
};

const loginButtonStyle = {
  backgroundColor: "rgb(200, 200, 200)",
  fontWeight: "600",
  border: "2px solid",
  padding: "5px 15px",
  fontFamily: "Nunito",
  color: "rgb(100,100,200)"
};

const textboxStyle = {
  border: "2px solid var(--lightblue)"
};














export {formPageMainStyle};
export {pageTitleStyle};
export {formContainerStyle};
export {formStyle};
export {inputStyle};
export {formSecondaryLabelStyle};
export {tinySpaceBetweenLastInputAndMessageStyle};
export {buttonStyle};
export {textboxStyle};
export {loginButtonStyle};
