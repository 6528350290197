import React, { createContext, useContext, useState } from 'react';

const DirectionContext = createContext();

export const useDirection = () => useContext(DirectionContext);

export const DirectionProvider = ({ children }) => {
  const [direction, setDirection] = useState('ltr'); // initial state

  const toggleDirection = () => {
    setDirection(prevDirection => prevDirection === 'ltr' ? 'rtl' : 'ltr');
    console.log("Direction Flipped To: " + direction);
  };

  return (
    <DirectionContext.Provider value={{ direction, toggleDirection, setDirection}}>
      {children}
    </DirectionContext.Provider>
  );
};
