import { Box, CircularProgress, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { CSSTransition } from 'react-transition-group';
const { getIsLoading } = require("../utils/loadingUtils")

// function useScrollPosition() {
//     const [scrollPosition, setScrollPosition] = useState(0);

//     useEffect(() => {
//       const handleScroll = () => {
//         const position = window.pageYOffset || document.documentElement.scrollTop;
//         setScrollPosition(position);
//       };

//       window.addEventListener('scroll', handleScroll);

//       // Cleanup event listener on component unmount
//       return () => {
//         window.removeEventListener('scroll', handleScroll);
//       };
//     }, []); // Empty dependency array ensures this effect runs once on mount

//     return scrollPosition;
//   }


function useDivHeight() {
    const [height, setHeight] = useState(0);
    const divRef = useRef(null);
    useEffect(() => {
        const updateHeight = () => {
            if (divRef.current) {
                setHeight(divRef.current.offsetHeight);
            }
        };

        updateHeight(); // Set initial height

        // Add a resize listener to update height when the window resizes
        const observer = new ResizeObserver(() => {
            updateHeight();
        });

        if (divRef.current) {
            observer.observe(divRef.current);
        }
    }, []);

    return [height, divRef];
}

const FadingComponent = ({ isVisible, children }) => {
    const nodeRef = useRef(null);
    return (
        <CSSTransition
            in={isVisible}
            nodeRef={nodeRef}
            timeout={500} // Duration of the transition
            classNames="fade"
            unmountOnExit  // Unmount the component when exit is finished
        >

            <div ref={nodeRef} style={{ position: 'sticky', top: 0, zIndex: 1002, height: '100%' }}>
                {children}
            </div>
        </CSSTransition>
    );
};


export function LoadingComponent({ children }) {
    // const scrollY = useScrollPosition();
    const [height, divRef] = useDivHeight();

    return (
        // <Box
        //     sx={{
        //         position: 'relative',
        //         display: 'flex',
        //         alignItems: 'center',
        //         justifyContent: 'center',
        //         minHeight: '100vh',
        //     }}
        // >
        //     <Typography
        //         variant="div"
        //         component="div" 
        //         style= {{flexGrow: 1}}  
        //     >
        //         <div style={{
        //             display: 'flex',
        //             flexDirection: 'column', 
        //             alignItems: 'flex-start',
        //             justifyContent: 'flex-start',
        //             minHeight: '100vh',
        //         }}>
        //             <div style={{
        //                 alignItems: 'center',
        //                 JustifyContent: 'center',
        //                 minWidth: '100vw',
        //                 minHeight: '100vh',
        //             }}>
        //                 {children}
        //             </div>

        //         </div>

        //     </Typography>
        //     {true && <Box
        //         onClick={(e) => {e.stopPropagation()}}
        //         sx={{
        //             position: 'absolute',
        //             top: 0,
        //             left: 0,
        //             right: 0,
        //             bottom: 0,
        //             backgroundColor: 'rgba(0, 0, 0, 0.5)',
        //             display: 'flex',
        //             alignItems: 'center',
        //             justifyContent: 'center',
        //             opacity: 1,
        //             zIndex: 1001,
        //             transition: 'opacity 0.3s ease',
        //         }}
        //     >
        //         <CircularProgress
        //             sx={{
        //                 color: 'white',
        //             }}
        //             size={40}
        //         />
        //     </Box>}
        // </Box>


        <div>

            <div
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    height: height,
                }}
                onClick={(e) => { e.stopPropagation() }}
            >
                <FadingComponent isVisible={getIsLoading()}>
                    <Box
                        sx={{
                            position: 'sticky',
                            top: 0,
                            height: '100vh',
                            width: '100vw',
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}>
                        <CircularProgress
                            sx={{
                                position: 'relative',
                                color: 'white',
                            }}
                            size={40}
                        />
                    </Box>
                </FadingComponent>
            </div>
            <div ref={divRef}>
                {children}
            </div>
        </div>
    );
}