const { useState, useEffect } = require("react");
let isLoading, setLoading;
let incCnt, decCnt;

function useLoading(){
    const [_isLoading, _setLoading] = useState(false);
    const [counter, setCounter] = useState(0);
    const [increaseCounter, setIncreaseCounter] = useState(0);
    const [decreaseCounter, setDecreaseCounter] = useState(0);
    isLoading = _isLoading;
    setLoading = _setLoading;
    useEffect(() => {
        if (counter > 0){
            setLoading(true);
        }else{
            setLoading(false);
        }
    },[counter])
    function _incCnt(){
        setCounter(cnt => cnt + 1);
    }
    function _decCnt(){
        setCounter(cnt => cnt - 1);
    }
    incCnt = _incCnt;
    decCnt = _decCnt;
}

function getIsLoading(){
    return isLoading;
}

async function doLoading(func){
    incCnt();
    const res = await func();
    decCnt();
    return res;
}

export {useLoading}
export {getIsLoading}
export {doLoading}