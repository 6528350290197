import React, { useEffect, useState, useRef } from "react";
import {microServices, getMicroserviceUri} from '../utils/uri.js'
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  InputGroup,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { isUserDefined, setUser } from "../utils/userCookie.js";
import "./login.css";
import { FaEnvelope, FaLock } from "react-icons/fa";

import { getKCAuthed, getKCIsLoading, handleLogin } from "../utils/keycloakUtils.js";
import { isUserRegisteredAndApproved, getUser } from "../utils/util.js";
import { useCriticalSection } from "../utils/lock.js";
import StylizedFormLayout from "../Components/StylizedFormLayout.js";
import { RestaurantRounded } from "@mui/icons-material";
import Navbar from "../Components/Navbar.js";

function Login() {
  
  const [regAppr, setRegAppr] = useState({registered: null, approved: null}); 
  const navigate = useNavigate();
  const isUserSet = useRef(false);
  const criticalSectionHandler = useCriticalSection();
  useEffect(() => {
    const asyncEffect = async () =>{
      await criticalSectionHandler(async () => {
        if (isUserDefined() || isUserSet.current){
          return;
        }
        if (regAppr.registered === true && regAppr.approved === false){
          return;
        }
        if (getKCIsLoading() && !getKCAuthed()){
          return;
        }
        if (!getKCAuthed()){
          handleLogin();
          return;
        }
        const regApprVal = await isUserRegisteredAndApproved();
        //console.log("regApprVal",regApprVal);
        if (regApprVal.registered && regApprVal.approved){
          const user = await getUser();
          if (!user){
            return;
          }
          console.log(user);
          setUser(user);
          isUserSet.current = true;
        }else if (!regApprVal.registered){
          navigate('/sign-up');
        }
        
        setRegAppr(regApprVal);
      })
    }
    asyncEffect();
  }, [getKCAuthed(), getKCIsLoading()]);



  if (!getKCAuthed()) {
    return <div>Loading...</div>;
  }

  if (regAppr.registered && !regAppr.approved){
    return <div>
      <Navbar/>
      <StylizedFormLayout header='Pending to be approved' noPic={true}/>
    </div>
  }

  return (
    <div>
      <p>Loading...</p>
    </div>
  );
}

export default Login;
