import React, { useState, useRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import RichTextComponent from './RichTextComponent'
const RichTextForm = ({onSubmit}) => {
  const [editorHtml, setEditorHtml] = useState('');

  const handleChange = (html) => {
    setEditorHtml(html);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(editorHtml);
    setEditorHtml("");
};

  return (
    <div style={{
        width: "100%"
    }}>
      <form onSubmit={handleSubmit} style={{
        width:"100%",
        textAlign: "center",
        alignContent: "center"
      }}>
        <RichTextComponent value={editorHtml} onChange={handleChange}/>
        <button type="submit" style={{
            display: 'inline-block',
            width: "15%",
            margin: "10px",
            borderRadius: "5px",

        }}> Submit </button>
      </form>
    </div>
  );
};

export default RichTextForm;