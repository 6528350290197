import React from "react";

import {microServices, getMicroserviceUri} from '../../utils/uri.js'
import {fetchPendingUsers, fetchWrapper} from '../../utils/util.js'

import { Button } from "@mui/material";
import { Container } from "react-bootstrap";
import "../../App.css";
import { GiCancel } from "react-icons/gi";
import { TiTick } from "react-icons/ti";
import { useEffect, useState } from "react";
import { useDirection } from '../../Components/DirectionContext.js';
import { useTranslation } from 'react-i18next';

function UserPendingPage() {
  const [users, setUsers] = useState([]);
  const [dependency, setDependency] = useState(0);

  function triggerDependency() {
    setDependency(dependency + 1);
  }

  async function sendRequest(inputs, uri) {
    let response;
    try {
      response = await fetchWrapper(uri, 'POST',{
        'Content-Type': 'application/json',
        // Add any other headers you might need
      },
      JSON.stringify(inputs),
      );

    } catch (e) {
      console.log("fetch error", e);
      return null; // Return null or handle error accordingly
    }
    if (!response || !response.ok) {
      console.log("response:");
      console.log(response);
      console.log('Network response was not ok', response);
      return null; // Return null or handle error accordingly
    }
    const data = await response.json();
    console.log('Server response:', data);
    triggerDependency();
    return data;
  }

  async function updateStatus(stat, email_address) {
    const inputs = {
      email_address: email_address,
      approved: stat
    };
    const uri_ = getMicroserviceUri(microServices.USERS) + '/updateUserStatus';
    await sendRequest(inputs, uri_);
  }

  async function deleteUser(email_address) {
    const inputs = {
      email_address: email_address,
    };
    const uri = getMicroserviceUri(microServices.USERS) + '/deleteUser';
    await sendRequest(inputs, uri);
  }

  async function rejectClick(email_address) {
    await deleteUser(email_address);
  }

  async function acceptClick(email_address) {
    await updateStatus(true, email_address);
  }

  useEffect(() => {
    const fn = async () => {
      const fetchedUsers = await fetchPendingUsers();
      const filteredUsers = fetchedUsers.filter(user => user.blocked === false);

      if (filteredUsers) {
        setUsers(filteredUsers);
      }
    };
    fn();
  }, [dependency]);

  const {t} = useTranslation();

  return (
    <div data-testid="userPending">
      <Container style={{ direction: useDirection()?.direction, padding: 0, margin: 0 }}>
        <div style={{ overflowX: "auto", width: "100%", padding: 0, margin: 0 }}>
          <table style={{ width: "100%", minWidth: "600px", padding: 0, margin: 0 }}>
            <thead>
              <tr>
                <th>{t("UserPending.name")}</th>
                <th>{t("UserPending.email")}</th>
                <th>{t("UserPending.organization")}</th>
                <th>{t("UserPending.job")}</th>
                <th>{t("UserPending.details")}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {users.map((row) => (
                <tr key={row._id}>
                  <td>{row.first_name + ' ' + row.last_name}</td>
                  <td>{row.email_address}</td>
                  <td>{row.orginization}</td>
                  <td>{row.position}</td>
                  <td>{row.about}</td>
                  <td>
                    <Button
                      className="submit-group"
                      style={{
                        backgroundColor: "rgb(49,128,64)",
                        color: "var(--white)",
                        fontWeight: "550",
                        border: "1px solid var(--green)",
                        marginRight: "8px",
                      }}
                      onClick={() => { acceptClick(row.email_address); }}
                      type="submit"
                    >
                      <TiTick style={{
                        fontSize: "25px",
                        color: "var(--white)",
                        fontWeight: "650",
                      }} />
                    </Button>
                    <Button
                      className="submit-group"
                      style={{
                        backgroundColor: "rgb(27,31,87)",
                        color: "var(--white)",
                        fontWeight: "550",
                        border: "1px solid var(--red)",
                      }}
                      email={row.email_address}
                      onClick={() => { rejectClick(row.email_address); }}
                      type="submit"
                    >
                      <GiCancel style={{
                        fontSize: "25px",
                        color: "var(--white)",
                        fontWeight: "650",
                      }} />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Container>
    </div>
  );
}

export default UserPendingPage;
