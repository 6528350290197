import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { CookiesProvider } from 'react-cookie';
import './i18n';
import 'bootstrap/dist/css/bootstrap.min.css';
// Supporting RTL (Right to Left) languages
import { DirectionProvider } from './Components/DirectionContext';
import { AuthProvider } from 'react-oidc-context';
import { keycloakConfig } from './utils/util';
import { WebStorageStateStore } from 'oidc-client-ts';

const oidcConfig = {
  authority: keycloakConfig.url + 'realms/' + keycloakConfig.realm,
  client_id: keycloakConfig.clientId,
  redirect_uri: window.location.origin,
  post_logout_redirect_uri: window.location.origin,
  response_type: 'code',
  scope: 'openid profile email',
  automaticSilentRenew: false,
  userStore: new WebStorageStateStore({ store: window.localStorage })
};

const root = ReactDOM.createRoot(document.getElementById('root'));





root.render(
  <CookiesProvider >
    <DirectionProvider>
      <AuthProvider {...oidcConfig}>
        <App />
      </AuthProvider>
    </DirectionProvider>
  </CookiesProvider>


);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
